import axios from "axios";

declare const PRODUCTION: any;

interface IAuthService {
  scheme: string,
  clientId: string,
  scopes: string,
  endpoint: string,
  refreshTokenInterval: number
}
interface ISpidService {
  scheme: string,
  clientId: string,
  clientSecret: string,
  scopes: string,
  endpoint: string,
  refreshTokenInterval: number
}


interface IEsriService {
  endpoint: string
}

interface ILayerInfo {
  url: string,
  titlePopup: string,
  contentPopup: string
  nomeTratto?: string
  codiceTratto?: string
  idAttributes?: string
  vMaxTR?: string
  vMaxPGp?: string
  vMaxPGg?: string
}

interface ISettings {
  baseUrl: string,
  authService: IAuthService,
  spidConfiguration: ISpidService,
  protocollazioneSid: string,
  corpiIdriciFiumi: ILayerInfo
  corpiIdriciSpecchiAcqua: ILayerInfo,
  corpiIdriciSotterranee: ILayerInfo,
  pagoPa: {
    tooltipContent: string,
    guideFileName: string
  },
  concessioniPagamentiMinimiPresentazione: number,
  numeroPraticaConcessioniIniziale: number,
}

// You can customize settings below :)
const settings: ISettings = {
  baseUrl: 'https://localhost:7019',
  authService: {
    scheme: "rgu_idrico",
    endpoint: "https://localhost:7019",
    clientId: 'rgu_idrico',
    scopes: 'openid profile email offline_access',
    refreshTokenInterval: 5
  },
  spidConfiguration: {
    scheme: "spid",
    endpoint: "https://localhost:7019",
    clientId: "rgu_idrico_spid",
    clientSecret: "c15ec0c0-979a-4928-9fef-13e97a35a3fd",
    scopes: "openid profile email offline_access",
    refreshTokenInterval: 5
  },
  protocollazioneSid: 'https://localhost:7019',
  corpiIdriciFiumi: {
    url: "https://services-eu1.arcgis.com/djnMU8K5SsVj4H31/arcgis/rest/services/Fiumi_Umbria_2024/FeatureServer/0",
    titlePopup: "NAME",
    contentPopup: "TYPE",
    nomeTratto: "TRUNK",
    codiceTratto: "WBCODE",
    idAttributes: "rID",
    vMaxTR: "VmaxTR",
    vMaxPGp:"VmaxPGp",
    vMaxPGg:"VmaxPGg",
  },
  corpiIdriciSpecchiAcqua: {
    url: "https://services-eu1.arcgis.com/djnMU8K5SsVj4H31/ArcGIS/rest/services/Specchi_d_acqua_Umbria_2024/FeatureServer/4",
    titlePopup: "NOME",
    contentPopup: "NATURA",
    idAttributes: "ID",
    vMaxTR: "VmaxTR",
    vMaxPGp:"VmaxPGp",
    vMaxPGg:"VmaxPGg",
  },
  corpiIdriciSotterranee: {
    url: "https://services-eu1.arcgis.com/djnMU8K5SsVj4H31/arcgis/rest/services/Corpi_idrici_sotterranei_2024/FeatureServer/1",
    titlePopup: "COD_IDRO_S",
    contentPopup: "NOME_IDRO_",
    idAttributes: "COD_IDRO_S",
  },
  pagoPa: {
    tooltipContent: "Tramite il sito di pagoumbria accedere alla sezione 'Pagamenti Spontanei', selezionare dall'elenco 'Regione Umbria' e successivamente 'IDRICO'. Fornite i dati richiesti e seguite i passaggi a schermo. Per una guida completa cliccare il pulsante di aiuto",
    guideFileName: "pagoPaGuide.pdf"
  },
  concessioniPagamentiMinimiPresentazione: 2,
  numeroPraticaConcessioniIniziale:0,
}


export async function loadSettings() {

  let response = null;

  try {
    response = await axios.get("/settings.custom.json");
    Object.assign(settings, response.data);
  } catch { }

  if (!response)
    try {
      response = await axios.get("/settings.json");
      Object.assign(settings, response.data);
    } catch { }

  try {
    if (PRODUCTION !== undefined && PRODUCTION) {
      console.error("NO CONFIGURATION FOUND !!", "please provide settings.json or settings.custom.json file !")
    }
  } catch { }

}



export default settings
